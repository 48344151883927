import React, { useEffect } from "react"

import Map from './Map';
import { promiseToFlyTo } from 'lib/map';
import L from 'leaflet';
import { Popup } from 'react-leaflet';
import MyMarker from './extended_marker'
// import { css } from "@emotion/core"


// import { Actionbutton } from './common'


const LOCATION = {
    lat: 40.693027,
    lng: -73.982204,
  };

  const AdressLocation = {
    lat: 40.693027,
    lng: -73.982204,
  };
  const DEFAULT_ZOOM = 2;
  const ZOOM = 12;
  
  const timeToZoom = 2000;
  const timeToOpenPopupAfterZoom = 2000;
//   const timeToUpdatePopupAfterZoom = timeToOpenPopupAfterZoom + 3000;
  
//   const popupContentHello = `<p>Hello 👋</p>`;
  const popupContentHello = (
      <div>
        <h1 style={{margin:"0", marginTop:'2rem', marginBottom:'1rem', padding:'0', fontWeight:'700', textRendering:'optimizeLegibility', lineHeight:'1', color:'#333'}}>Feodoroff Law</h1>
        <p style={{textAlign:'center', marginBottom:'2rem', color:'#888'}}>147 Prince Street, Brooklyn, N.Y. 11201</p>
      </div>
  )

 
   

const MapComponentNew = (props) => {

    // const markerRef = useRef();
    const CENTER = [props.center.lat, props.center.lng];


    async function mapEffect({ leafletElement } = {}) {
    //     if ( !leafletElement ) return;
    
    //     const popup = L.popup({
    //       maxWidth: 800,
    //     });
    
    //     const setLocation = () =>{
    //       // return {lat: 38.9072, lng: -77.0369}
    //       return props.AdressLocation[0]
    //     }
  
      
    //     const location = await setLocation()
    //   //   const location = await getCurrentLocation().catch(() => LOCATION );
    
    //     const { current = {} } = markerRef || {};
    //     const { leafletElement: marker } = current;
  
    //     marker.setLatLng( location );
    //     popup.setLatLng( location );
        
    //     if(props.sign){
    //       popup.setContent( popupContentHello );
    //     }
    
        // setTimeout( async () => {
        //   await promiseToFlyTo( leafletElement, {
        //     // zoom: ZOOM,
        //     zoom: props.zoom,
        //     center: props.center,
        //   });
  
        // //   if(props.sign){
        // //     marker.bindPopup( popup );
        // //     setTimeout(() => marker.openPopup(), timeToOpenPopupAfterZoom );
        // //   }else{
        // //     marker.closePopup()
        // //   }
  
        //   // setTimeout(() => marker.setPopupContent( popupContentGatsby ), timeToUpdatePopupAfterZoom );
        // }, timeToZoom );
      }

    //   useEffect(() => {

    //     setTimeout( async () => {
    //         await promiseToFlyTo( leafletElement, {
    //             // zoom: ZOOM,
    //             zoom: props.zoom,
    //             center: props.center,
    //         });
    //         }, timeToZoom );

    //     }, [])

    const mapSettings = {
        center: CENTER,
        defaultBaseMap: 'OpenStreetMap',
        // zoom: DEFAULT_ZOOM,
        zoom: props.zoom,
        mapEffect,
      }

      return(
        <Map {...mapSettings}>
            <MyMarker onClick={()=>props.setLocation(1)} sign={props.sign} index={1} selected={props.selected} position={[props.AdressLocation[0].lat, props.AdressLocation[0].lng]}>
                {props.sign ? (
                    <Popup>
                        {popupContentHello}
                    </Popup>
                ):<></>}
            </MyMarker>

            {props.AdressLocation.length > 1 ? (
                <>
                    <MyMarker onClick={()=>props.setLocation(2)} sign={props.sign} index={2} selected={props.selected} position={[props.AdressLocation[1].lat, props.AdressLocation[1].lng]}>
                      {props.sign ? (
                          <Popup>
                            <h2 style={{color:'#333'}}>{props.messages[0]}</h2>
                          </Popup>
                      ):<></>}
                    </MyMarker>
                    <MyMarker onClick={()=>props.setLocation(3)} sign={props.sign} index={3} selected={props.selected} position={[props.AdressLocation[2].lat, props.AdressLocation[2].lng]}>
                      {props.sign ? (
                          <Popup>
                            <h2 style={{color:'#333'}}>{props.messages[1]}</h2>
                          </Popup>
                      ):<></>}
                    </MyMarker>

                </>
                    ) : 
                <>
                </>
            }
        </Map>
      )
}

export default MapComponentNew
