import React, {useState} from "react"
import Footer from '../components/footer'
import BtnUp from '../components/btn_up'
import NavBar from '../components/nav-bar'
// import MissionSec from '../components/mission_section'
// import LocationSec from '../components/location_section'
import LocationSecNew from '../components/loacation_new'
import BioSec from '../components/bio_section'
import NlSign from '../components/nl_sign'
import ConsulSign from '../components/consul_sign'
import { getShowNL, setShowNL } from "../cookies/nl_sign_cookie"
import { getMenuItem, setMenuItem } from "../cookies/submenu_cookie"
// import scrollTo from 'gatsby-plugin-smoothscroll'
import { navigate } from "gatsby"


// import WhyUsSec from '../components/why_section'
import { Body } from '../components/common'




export default () =>{ 
    const [consultation, setConsultation] = useState(false);

    const openConsulSign = (evt) =>{
        setConsultation(true)
    }

    if(!getShowNL || !getShowNL()['show']){
        setShowNL({'show': true})
    }

    // var item1 = 1
    // var item2 = 0
    // var show = true

    // if(!getShowNL || !getShowNL()['show']){
    //     setShowNL({'show': true})
    // }else{
    //     show = getShowNL()['show']
    // }

    // if(!getMenuItem){
    //     item1 = 1
    //     item2 = 0
    //     setMenuItem({'item': 1, 'item2': 0})
    // }else{
    //     item1 = getMenuItem()['item']
    //     item2 = getMenuItem()['item2']
    // }

    // if(item1 == undefined || item2 == undefined){
    //     item1 = 1
    //     item2 = 0
    // }

    // const [service, setService] = useState(item1);
    // const [perDiem, setPerDiem] = useState(item2);
    
    const changeService = (evt) =>{
        // console.log('option: ', evt.target.id)
        var menu_temp = getMenuItem()

        if(evt.target.id === 'true'){
            // scrollTo('#is')
            // setMenuItem({'item': evt.target.name})
            menu_temp['item'] = evt.target.name
            setMenuItem(menu_temp)
            navigate('/#is')
            // setService(evt.target.name)
        }else{
            navigate('/per_diem')
        }
        // else{
        //     // scrollTo('#per_diem')
        //     menu_temp['item2'] = parseInt(evt.target.name) - 1
        //     setMenuItem(menu_temp)
        //     navigate('/#per_diem')
        //     // setPerDiem(parseInt(evt.target.name) - 1)
        // }
    }

    return(
        <Body>
            <NavBar pickService={changeService} iService={0} perDiem={0} abs={true} isSticky={false} consulOpen={openConsulSign}/>
            <NavBar pickService={changeService} iService={0} perDiem={0} isSticky={true} consulOpen={openConsulSign}/>
            <div style={{height:'100px'}}></div>
            <NlSign delay={60} open={false}/>
            <ConsulSign open={consultation} onClose={()=>setConsultation(false)}/>
            {/* <MissionSec/> */}
            <BioSec/>
            {/* <WhyUsSec/> */}
            {/* <LocationSec/> */}
            <LocationSecNew/>
            <Footer/>
            <BtnUp/>
        </Body>
    )
}

