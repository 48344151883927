// import React, {useState} from "react"
import React, { useEffect, useState } from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
// import Img from "gatsby-image"
// import { Link } from "gatsby"
// import { useImagesPhilosophy } from "../hooks/use-images-philosophy"
import { TextContainer } from './common'
import MapComponentNew from './map_component_new';
import InfoBoxLocation from './info_box'

import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import MapVideo from "../videos/Final2.mp4"




const Bg = styled.div`
   background-color: white;
   width: 100%;
   color: white;
   position: relative;
   overflow: hidden;
   height: 500px;
  
   @media (max-width: 750px) {
        height: 56.5vw;                  
    }
   h2{
       color: white;
       text-align: center;
   }
   h3{
    color: white;
    text-align: left;
    }
    p{
       text-align: left;    
       margin-bottom: 2rem;    
    }

`
// width: 65%;
// float: left;
// z-index: 1;



const MapContainer = styled.div`
    height: 500px;
    width: 889px;
    position: absolute;
    right: 0;
    z-index: 0;
    background-color: transparent;
    
    @media (max-width: 1300px) {
        left: -5vw;
    }

    @media (max-width: 750px) {
        left: 0;
        height: 56.5vw;      
        width: 100%;              
    }
`


const SignCont = styled.div`
    height: 500px;
    width: 889px;
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    
    @media (max-width: 1300px) {
        left: -5vw;
    }

    @media (max-width: 750px) {
        left: 0;
        height: 56.5vw;      
        width: 100%;              
    }
`

const Tooltip = styled.div`
    height: 40px;
    width: 30px;
    background-color: transparent;
    position: absolute;
    top: 56%;
    left: 57%;
    cursor: pointer;

    @media (max-width: 750px) {
        height: 5vw;
        width: 4vw;
    }
`
const Sign = styled.div`
    background-color: white;
    color: #333;
    text-align: center;
    border-radius: 6px;
    padding: 5px 20px;
    position: absolute;
    top: 32%;
    left: 50.1%;
    margin-left: -60px;
    transition: 0.3s;


    :after {
        content: "";
        position: absolute;
        top: 100%;
        left: 50%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color: white transparent transparent transparent;
    }

    @media (max-width: 945px) {
        display: none;
    }

    ${p => p.show == 1 ? css`
        visibility: visible;
        opacity: 1;
    ` : css`
        visibility: hidden;
        opacity: 0;

    `}    
`




const Mask = styled.div`
    width: 65%;
    height: 500px;
    float: left;
    position: relative;
    z-index: 0;
    @media (max-width: 750px) {
        height: 56.5vw;      
        width: 100%;              
    }
`


const InfoContainer = styled.div`
    width: 35%;
    float: left;
    color: #666;
    background-color: white;
    height: 500px;
    position: relative;
    z-index: 2;
    h2, h3{
        color: #333;
        text-align: left;
        margin: 0;
        @media (max-width: 950px) {
            font-size: 1.2rem;                         
        }
    }
    h3 {
        color: #666;
    }
    padding: 4rem;

    @media (max-width: 750px) {
        display: none;             
    }
`

const Shade = styled.div`
    width: 100%;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    display: none;             
    transition: 0.5s; 
    
    @media (max-width: 750px) {
        display: block; 
        height: 56.5vw;            
        ${p => p.show != 0 ? css`
            visibility: visible;
            background-color: rgba(0,0,0,0.6);
        ` : css`
            visibility: hidden;
            background-color: rgba(0,0,0,0);
        `}         
    }

    h1, h2 {
        color: white;
        text-align: left;
    }
   
`


const Disappear = styled.div`
    ${p => p.show != 0 ? css`
        display: block;
    ` : css`
        display: none;
    `} 
`

const VideoContainer = MapContainer.withComponent('video')

let temp = false
let zoom = 7
let locationName  = ['', 'New York City', 'Buffalo, N.Y.', 'Newark, N.J.']
let email = ['', 'info@felawpllc.com', 'info@felawpllc.com', 'info@felawpllc.com']
let tel = ['', '(718)-715-1667', '(718)-715-1667', '(718)-715-1667']

const LocationSecNew = (props) =>{
    // Images = useImagesPhilosophy()

    if(typeof window !== "undefined"){
        if(window.innerWidth <= 750){
            temp = true
        }else{
            temp = false
        }
    }

    const [mobileLayout, setMobileLayout] = useState(temp);
    const [location, setLocation] = useState(1);

    
    if(mobileLayout){
        zoom = 6
    }

    const Resizehandler = () =>{
        if(window.innerWidth <= 750){
            setMobileLayout(true)
        }else{
            setMobileLayout(false)
        }
    }

    const setNY = (evt) =>{
        if(location == 0){
            setLocation(1)
        }else if(location == 1){
            setLocation(0)
        }else{
            setLocation(1)
        }
    }

    useEffect(() => {
        // initiate the event handler
        window.addEventListener('resize', Resizehandler);
    
        // this will clean up the event every time the component is re-rendered
        return function cleanup() {
          window.removeEventListener('resize', Resizehandler);
        };
      });

    return(
    
        <Bg>
            <InfoContainer>
                <h2 style={{marginBottom:'1rem'}}>Areas of Service</h2>
                <p style={{color:'#888', fontSize:'1.6rem'}}>Feodoroff Law PLLC represents clients all across the United States, with a special focus on New York City, Upstate New York, and the greater Tri-State region.</p>
                <InfoBoxLocation setmenuDisplay={setLocation} menuDisplay={location}/>
            </InfoContainer>
            {/* <MapContainer>
                <MapComponentNew selected={location} setLocation={setLocation} zoom={zoom} defaultZoom={2} center={{'lat': 41.9, 'lng':-76.3}} sign={!mobileLayout} messages={['Buffalo, N.Y.', 'Newark, N.J.']} AdressLocation={[{'lat': 40.693027, 'lng': -73.982204}, {'lat': 42.904722, 'lng': -78.849444}, {'lat': 40.72422, 'lng': -74.172574}]}/>
            </MapContainer> */}
            <Mask>
                <VideoContainer autobuffer="true" playsInline autoPlay loop muted>
                    <source src={MapVideo} type="video/mp4"/>
                    Your browser does not support the video tag.
                </VideoContainer>
                <SignCont>
                    <Tooltip onClick={setNY}/>
                    <Sign show={location}>
                        <h1 style={{margin:'0', marginTop:'2rem', marginBottom:'1rem', padding:'0', fontWeight:'700', textRendering:'optimizeLegibility', fontSize:'3rem', lineHeight:'1', color:'#333'}}>Feodoroff Law</h1>
                        <p style={{textAlign:'center', marginBottom:'2rem', color:'#888'}}>147 Prince Street, Brooklyn, N.Y. 11201</p>
                    </Sign>
                </SignCont>
            </Mask>
             <Shade show={location} onClick={()=>setLocation(0)}>
                <TextContainer style={{top:'50%'}}>
                    <Disappear show={location}>
                        <h1 style={{marginBottom:'2rem', marginTop:'0'}}>{locationName[location]}</h1>
                        <div style={{marginBottom:'2rem', overflow:'auto'}}>
                            <MdEmail size={32} style={{display:'block', float:'left', color:'white', marginTop:'-0.3rem'}}/><h2  style={{marginLeft:'1rem', display:'block', float:'left'}}>{email[location]}</h2>
                        </div>
                        <FaPhoneAlt size={32} style={{display:'block', float:'left', color:'white'}}/><h2  style={{marginLeft:'1rem', display:'block', float:'left'}}>{tel[location]}</h2>
                    </Disappear>     
                </TextContainer>
            </Shade>
        </Bg>   
       
    )
}
export default LocationSecNew
