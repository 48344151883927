import React from "react"
// import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
// import Img from "gatsby-image"
// import { Link } from "gatsby"
// import { useImagesPhilosophy } from "../hooks/use-images-philosophy"
// import { TextContainer, ActionbuttonInverted  } from './common'
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { RiArrowDropDownLine, RiArrowDropUpLine } from "react-icons/ri";



const LboxContainer = styled.div`
    margin-bottom: 2rem;
`
const LboxTitle = styled.div`
    border: 2px solid #f4f4f4;
    padding: 1.5rem;
    cursor: pointer;
`
const LboxInfo = styled.div`
    border: 2px solid #f4f4f4;
    border-top-style: none;
    padding: 2rem;
    font-size: 1.6rem;

    ${p => p.show ? css`
    display: block;
    ` : css`
    display: none;
    `}

    @media (max-width: 950px) {
        padding: 1rem;
        span{
            font-size: 1.2rem;
        }                       
    }
`

const Symbol = styled.span`
    margin-top: -0.7rem;
    float: right;
    ${p => p.show ? css`
    display: inline-block;
    ` : css`
    display: none;
    `}
`


// let Images =  {}

const LocationBox = (props) =>{
    
    let extended = false

    const clickHandler = ()=>{
        if(extended){
            props.change(0)
        }else{
            props.change(props.index)
        }
    }
    
    if(props.display == props.index){
        extended = true
    }

    return(
        <LboxContainer>
            <LboxTitle onClick={clickHandler}>
                <h3 style={{display:'inline-block'}}>{props.city}</h3><Symbol show={!extended}><RiArrowDropDownLine size={36} style={{ color: '#888', margin:'0', padding:'0'}}/></Symbol><Symbol show={extended}><RiArrowDropUpLine size={36} style={{ color: '#888', margin:'0', padding:'0'}}/></Symbol>
            </LboxTitle>
            <LboxInfo show={extended}>
                <MdEmail size={24} style={{display:'block', float:'left', color:'#ccc', marginTop:'0.2rem'}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{props.email}</span>
                <br></br>
                <br></br>
                <FaPhoneAlt size={24} style={{display:'block', float:'left', color:'#ccc', marginTop:'0.2rem'}}/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span>{props.tel}</span>
            </LboxInfo>
        </LboxContainer>
    )
}


const InfoBoxLocation = (props) =>{

    // const [menuDisplay, setmenuDisplay] = useState(1);


    return(
        <div>
            <LocationBox change={props.setmenuDisplay} display={props.menuDisplay} index={1} city='New York City' email='info@felawpllc.com' tel='(718)-715-1667'/>
            {/* <LocationBox change={props.setmenuDisplay} display={props.menuDisplay} index={2} city='Buffalo, New York' email='info@felawpllc.com' tel='(718) 715-1667'/>
            <LocationBox change={props.setmenuDisplay} display={props.menuDisplay} index={3} city='Newark, New Jersey' email='info@felawpllc.com' tel='(718) 715-1667'/> */}
        </div>
    )
}

export default InfoBoxLocation
