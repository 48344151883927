import React, {useState} from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import Img from "gatsby-image"
// import { Link } from "gatsby"
import { useImagesPhilosophy } from "../hooks/use-images-philosophy"
// import { TextContainer2 } from '../components/common'




const Bg = styled.div`
   width: 100%;
   color: #666;
   overflow: hidden;
   position: relative;

   h1{
       color: #333;
       text-align: left;
   }

   span{
       font-size: 1.4rem;
   }

   @media (max-width: 1000px) {
       h1{
            font-size: 2.4rem;
       }
    }
    
    @media (max-width: 630px) {
        h1{
            text-align: center;
        }
    }
`

const ImgContainer = styled.div`
    position: absolute;
    z-index:-1;
    width: 100%;
    bottom: 0;
`

const ContentContainer = styled.div`
    width: 60%;
    float: right;
    height: 100%;

    @media (max-width: 630px) {
       float: none;
       width: 90%; 
       margin-left: 5%;    
       
       
       h2{
            text-align: left;
       }  
       p{
            text-align: left;
            font-size: 1.2rem;
       }             
    }
`
const ParagraphContainer = styled.div`
    width: 90%;
    max-width: 630px;
    margin-bottom: 22vw;
    margin-top: 3rem;
    position: relative;
    overflow: auto;
    height: 30vw;

    p{
        font-size: 1.8rem;
    }

    @media (max-width: 630px) {
        width: 96%;
        margin-left: 2%;
        height: 60vw;
    }

`

const ReadM  = styled.span`
    color: blue;
    cursor: pointer;
    ${p => p.show? css`    
    ` : css`
        display: none;
    `}
    
`
const HiddenParag  = styled.p`
    margin-bottom: 1rem;
    
    ${p => p.show? css`    
    ` : css`
        display: none;
    `}
`



let Images =  {}



const BioSec = (props) =>{
    const [more, setMore] = useState(false);

    Images = useImagesPhilosophy()


    return(
        
            <Bg>
                <ImgContainer style={{left: '-10%'}}>
                    <Img style={{width: '110%'}} id='who_we_are_bg_img' fluid={Images['skyline_bg'].childImageSharp.fluid}/>   
                </ImgContainer>
                <ImgContainer css={css`
                            @media (max-width: 630px) {
                                display: none;                            
                            }
                            `} style={{left:'10%'}}>
                    <Img style={{width: '25%'}} id='who_we_are_bg_img' fluid={Images['nikolay_bg'].childImageSharp.fluid}/>   
                </ImgContainer>
                <h1 style={{textAlign:'center', textDecoration:'underline'}}>OUR TEAM</h1>
                <ContentContainer>
                    <ImgContainer css={css`
                                display: none;                            
                                @media (max-width: 630px) {
                                    display: block;                            
                                }
                                `} style={{position:'relative', margin:'auto', width: '45%', marginBottom:'-5%', marginTop:'10%'}}>
                        <Img style={{zIndex:'-1'}} id='who_we_are_bg_img' fluid={Images['nikolay_profile'].childImageSharp.fluid}/>  
                        <div id='s_philosophy_bg_shade' css={css`
                            width: 100%;
                            height: 100%;
                            top: 0;
                            position: absolute;
                            background: linear-gradient(0deg, rgba(255,255,255, 1) 0%, rgba(255,255,255,0) 30%);

                        `} style={{zIndex:'-1', position:'absolute'}}/>

                    </ImgContainer>
                    <h1 style={{marginTop:'2rem', marginBottom:'0'}}>Nikolay Feodoroff, Esq. </h1>
                    <h1 style={{marginTop:'1rem'}}>Principal Attorney</h1>
                    <ParagraphContainer>
                        <div style={{position:'absolute', top:'0', left:'0'}}>
                            <p style={{marginBottom:'1rem'}}>I am the founder of Feodoroff Law PLLC. and the lead attorney at the firm. I am deeply committed to representing non-citizens that want to remain in the United States. I am a firm believer in zealously advocating to keep families together and educate clients, to end the cycle of fear associated with immigration matters, which allow our clients to confidently tackle their immigration challenges.</p>
                            <p style={{marginBottom:'1rem'}}>During the course of my years of practice, I have gained a deep understanding of asylum law, immigration court representation, appeals work, detained matters, and representation at various benefit interviews. My work has led me to defend clients in immigration courts and in interviews for benefits all across the United States, including, appearances in New York, Newark, Elizabeth, Buffalo, Boston, Philadelphia, Baltimore, Virginia, Orlando, and Los Angeles. I have handled complex immigration cases, relating to reopening deportation/removal orders, contested areas of asylum law, crim-immigration, defective charging documents, waivers, and proving high levels of hardship to qualify for other forms of relief. I have also represented clients seeking a wide range of immigration benefits, including asylum, family-based petitions, removing conditional status, and naturalization. Over the years, I have represented a diverse array of clients and have won many cases that seemed to have little to no hope of success.</p>
                            {/* <p style={{marginBottom:'1rem'}}>During the course of my years of practice, I have gained a deep understanding of asylum law, immigration court representation, appeals work, detained matters, and representation at various benefit interviews. My work has led me to defend clients in immigration courts and in interviews for benefits all across the United States, including, appearances in New York, Newark, Elizabeth, Buffalo, Boston, Philadelphia, Baltimore, Virginia, Orlando, and Los Angeles. I have handled complex immigration cases, relating to reopening deportation/removal orders, contested areas of asylum law, crim-immigration, defective charging documents, waivers, and proving high levels of hardship to qualify for other forms of relief. I have also represented clients seeking a wide range of immigration benefits, including asylum, family-based petitions, removing conditional status, and naturalization. Over the years, I have represented a diverse array of clients and have won many cases that seemed to have little to no hope of success. <ReadM show={!more} onClick={()=>setMore(true)}>View More...</ReadM></p> */}
                            {/* <HiddenParag show={more} style={{marginBottom:'1rem'}}>Immigration law is not just a career, it is a lifelong passion. Over the years, I have gathered a wealth of experience from different vantage points, which I now harness to help our clients. I spent several years working for a respected New York City-Washington D.C based immigration law firm. I have also worked for a Western New York refugee resettlement agency and have volunteered countless hours for a national not-for-profit dedicated to representing immigrants at risk of losing their freedom of artistic expression. I am a proud alumni of the University at Buffalo Law, where I earned my law degree with honors. During law school, I held a prestigious semester-long internship with the Buffalo Immigration Court. I was also awarded the Alumni Public Interest Law Fellowship for my work with a refugee resettlement agency. Additionally, I was a member of the Niagara Moot Court team, that earned a spot at the national semi-finals round. I advocated for U.S. accountability for its immigration policies under international law. I earned my undergraduate degree at  Hunter College, the City University of New York, where I graduated with honors, majoring in political science, while being selected to the Thomas Hunter Honors Program.</HiddenParag>
                            <HiddenParag show={more}>Immigration and diversity are part of my personal life. My family is a melting pot. My wife is of Mexican-Nicaraguan descent. My family is of Russian-Slavic origins. In our home, we embrace all of these cultures, traditions, and languages. Additionally, we believe the United States must be a refuge for those that have been oppressed. My family was displaced and suffering various forms of harm during the early years of the Soviet Union. My grandfather was the first member of our family to come to the United States as a refugee, allowing our family to follow suit. <ReadM show={more} onClick={()=>setMore(false)}> View Less</ReadM></HiddenParag> */}
                            <p style={{marginBottom:'1rem'}}>Immigration law is not just a career, it is a lifelong passion. Over the years, I have gathered a wealth of experience from different vantage points, which I now harness to help our clients. I spent several years working for a respected New York City-Washington D.C based immigration law firm. I have also worked for a Western New York refugee resettlement agency and have volunteered countless hours for a national not-for-profit dedicated to representing immigrants at risk of losing their freedom of artistic expression. I am a proud alumni of the University at Buffalo Law, where I earned my law degree with honors. During law school, I held a prestigious semester-long internship with the Buffalo Immigration Court. I was also awarded the Alumni Public Interest Law Fellowship for my work with a refugee resettlement agency. Additionally, I was a member of the Niagara Moot Court team, that earned a spot at the national semi-finals round. I advocated for U.S. accountability for its immigration policies under international law. I earned my undergraduate degree at  Hunter College, the City University of New York, where I graduated with honors, majoring in political science, while being selected to the Thomas Hunter Honors Program.</p>
                            <p style={{marginBottom:'1rem'}}>Immigration and diversity are part of my personal life. My family is a melting pot. My wife is of Mexican-Nicaraguan descent. My family is of Russian-Slavic origins. In our home, we embrace all of these cultures, traditions, and languages. Additionally, we believe the United States must be a refuge for those that have been oppressed. My family was displaced and suffered various forms of harm during the early years of the Soviet Union. My grandfather was the first member of our family to come to the United States as a refugee, allowing our family to follow suit.</p>
                        </div>
                    </ParagraphContainer>
                    {/* <h1 style={{marginBottom:'4rem'}}>Nikolay Feodoroff</h1>
                    <div style={{}}>
                        <h2>Overview</h2>
                        <p style={{marginBottom:'2rem'}}>Nikolay Feodoroff is a representative lawyer</p>
                    </div>    
                    <div style={{marginBottom:'2rem'}}>   
                        <h2>Education</h2>
                        <span>University at Buffalo Law School, Juris Doctor, Cum Laude </span>
                        <br></br>
                        <span>Hunter College, City University of New York, Bachelor of Arts, Magna Cum Laude, Thomas Hunter Honors Program</span>
                    </div> 
                    <div style={{}}>  
                        <h2>Memberships</h2>
                        <span>New York State Bar</span>
                        <br></br>
                        <span>District of Columbia Bar</span>
                        <br></br>
                        <span>Authorized to practice Immigration & Nationality Law in all 50 states</span>
                        <br></br>
                        <span>American Immigration Lawyers Association (AILA) member</span>
                        <br></br>
                        <span>New York County Lawyers Association (NYCLA)</span>
                    </div>     */}
                </ContentContainer>
            </Bg>   
        
       
    )
}

export default BioSec
